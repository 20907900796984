import axios from 'axios';
import { responseCodes, responseMessages } from 'src/translations/serverMessages';
import { signout } from 'src/contexts/JWTAuthContext';

//const baseRoute = 'https://bot.meshbrasil.com/';
const baseRoute = '/';

export interface ApiResponse<T> {
    success: boolean;
    message?: string;
    data?: T;
}

export function chatWebSocket(
    chatUID: string | undefined,
    onMessage: (this: WebSocket, ev: MessageEvent<any>) => any,
    onOpen: Function,
    onError: (this: WebSocket, ev: MessageEvent<any>) => any): WebSocket {

    let route = '';
    if (chatUID) {
        route = baseRoute + 'v2/admin/chat?uid=' + chatUID;
    } else {
        route= baseRoute + 'v2/admin/chat';
    }

    const websocket = new WebSocket(route);
    websocket.onmessage = onMessage;
    websocket.onopen = () => {
        onOpen(false);
        websocket.send(axios.defaults.headers.common.Authorization);
    };
    websocket.onerror = onError;
    websocket.onclose = () => {
        console.log('WebSocket connection closed');
    };

    return websocket;
}

export async function handleApiCall<T>(
    method: string,
    route: string,
    data?: {query?: string; body?: any}
): Promise<ApiResponse<T>> {

    try {
        let path = baseRoute + route;

        if (data && data.query) {
            path += data.query;
        }

        const response = await axios[method](path, data? data.body : undefined);

        const prefix = responseCodes[method + ':' + route];
        const tag = responseMessages.en[prefix + '200'];
        const message = (tag ? prefix : 'i') + '200';
       
        return { success: true, message, data: response.data };
    } catch (error) {
        console.log(method  + ' ' + route + ' failed: ' + error.message);

        if (error.message === 'Network Error') {
             return { success: false, message: 'net' };
        }

        if (error.response) {

            if (error.response.status === 500) {
                return { success: false, message: error.response.data.message }
            }

            if (error.response.status === 401 &&
                route !== 'v2/public/auth' &&
                route !== 'v2/public/reset') {
                setSession(null);
                return { success: false, message: 'i401' };
            }
            if (error.response.status === 400) {
                return { success: false, message: 'i400' };
            }
            if (error.response.status === 404) {
                signout();
                return { success: false, message: 'i404' };
            }
        }

        const errorCode = error.response.status.toString();
        const prefix = responseCodes[method + ':' + route];
        const tag = responseMessages.en[prefix + errorCode];
        const message = (tag ? prefix : 'i') + errorCode;

        return { success: false, message };
    }
}

export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization;
    }
}
