import { FC, useState, createContext } from 'react';

type SidebarContext = {
  title: string;
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  defineTitle: (input:string) => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [title, setTitle] = useState('greeting');

  const defineTitle = (input: string) => {
    setTitle(input);
  }

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  }

  const closeSidebar = () => {
    setSidebarToggle(false);
  }

  return (
    <SidebarContext.Provider
      value={{ title, sidebarToggle, toggleSidebar, closeSidebar, defineTitle }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
