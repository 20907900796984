import { FC, ReactNode, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  scrollToBottomOnUpdate?: boolean; // Optional property
}

const Scrollbar: FC<ScrollbarProps> = ({ className, children, scrollToBottomOnUpdate = false, ...rest }) => {
  const theme = useTheme();
  const scrollbarRef = useRef<Scrollbars>(null);

  useEffect(() => {
    if (scrollToBottomOnUpdate && scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom();
    }
  }, [children, scrollToBottomOnUpdate]);

  return (
    <Scrollbars
      ref={scrollbarRef}
      autoHide
      renderThumbVertical={() => (
        <Box
          sx={{
            width: 5,
            background: `${theme.colors.alpha.black[10]}`,
            borderRadius: `${theme.general.borderRadiusLg}`,
            transition: `${theme.transitions.create(['background'])}`,
            '&:hover': {
              background: `${theme.colors.alpha.black[30]}`
            }
          }}
        />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  scrollToBottomOnUpdate: PropTypes.bool, // Prop type validation
};

export default Scrollbar;
