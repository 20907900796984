export const createLicenseCodes = {
    en: {
        cl200: 'License pack created successfully',
        cl404: 'Team does not exist',
        cl403: 'Admin not authorized to create license packs',
    },
    pt: {
        cl200: 'Pacote de licença criado com sucesso',
        cl404: 'Time não existe',
        cl403: 'Admin não autorizado a criar pacotes de licença',
    },
    es: {
        cl200: 'Paquete de licencias creado con éxito',
        cl404: 'El equipo no existe',
        cl403: 'Administrador no autorizado para crear paquetes de licencias',
    }
};

export const expireLicenseCodes = {
    en: {
        el200: 'License pack expired successfully',
        el404: 'Team does not exist',
        el403: 'Admin not authorized to expire license packs',
    },
    pt: {
        el200: 'Pacote de licença expirado com sucesso',
        el404: 'Time não existe',
        cl403: 'Admin não autorizado a expirar pacotes de licença',
    },
    es: {
        el200: 'Paquete de licencias expirado con éxito',
        el404: 'El equipo no existe',
        el403: 'Administrador no autorizado para expirar paquetes de licencias',
    }
};