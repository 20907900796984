import { createContext, useContext, useEffect, useState } from "react";
import { setSession } from "src/api/api";
import jwtDecode from "jwt-decode";
import { signIn } from "src/api/publicRoutes";
import { LocalizationContext } from "src/App";
import { useNavigate } from "react-router";

const isValidToken = (accessToken: string) => {
  const decodedToken = jwtDecode(accessToken) as { exp: number };
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export interface AuthContextInterface {
  role: string;
  uid: string;
  username: string;
  first_name: string;
  last_name: string;
  timezone: string;
}

const defaultState: AuthContextInterface = {
  role: "",
  uid: "",
  username: "",
  first_name: "",
  last_name: "",
  timezone: "",
};

export const AuthContext = createContext({
  ...defaultState,
  login: (user: string, password: string, browser: string) =>
    Promise.resolve({ success: false, message: null }),
  logout: () => {},
});


let logoutFunction: () => void;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const { setLanguage } = useContext(LocalizationContext);

  const logout = () => {
    setState(defaultState);
    setSession(null);
    navigate("/signin");
  };

  logoutFunction = logout;

  const login = async (user: string, password: string, browser: string) => {
    const response = await signIn(user, password, browser);
    if (response.success) {
      const token = response.data.token;

      const jwt = jwtDecode(token) as {
        role: string;
        uid: string;
        username: string;
        first_name: string;
        last_name: string;
        lang: string;
        timezone: string;
      };
      if (token && isValidToken(token)) {
        setState(jwt);
      }

      setLanguage(jwt.lang);
    }

    return { success: response.success, message: response.message };
  };

  const token = window.localStorage.getItem("accessToken");
  const jwt: {
    role: string;
    uid: string;
    username: string;
    first_name: string;
    last_name: string;
    lang: string;
    timezone: string;
  } | undefined = !token ? undefined : jwtDecode(token);

  useEffect(() => {
    const fetchToken = (async () => {
      
      if (!token) {return;}
      
      if (token && isValidToken(token)) {
        setSession(token);
      }

      setLanguage(jwt.lang);
    });
    fetchToken();
  }, [setLanguage, jwt, token]);

  const [state, setState] = useState<AuthContextInterface>(jwt ? jwt : defaultState);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const signout =  () => {
  return logoutFunction;
}
