export const osIDs = {
    1: 'Android',
    2: 'iOS',
    7: 'Unknown',
    8: 'Linux Client',
    9: 'ECS',
    10: 'PBX',
    11: 'SIP',
    12: 'Windows Desktop',
    13: 'OSX'
};

export const settingsLengthDefinitions = {
    ciphers: 500,
    message: 50,
    min: 1,
    passwordPlaintext: 300,
    prefix: 50,
    rateLimit: 10000,
    repeatedPword: 100,
    timeout: 10000,
    ttl: 100000,
};

const deviceInfo = {
    appVersion: 30,
    deviceName: 30,
    deviceToken: 300,
    osVersion: 30,
};

const browserInfo = {
    browser: 50,
};

const teamInfo = {
    city: 128,
    contactEmail: 320,
    country: 3,
    customSettings: 2000,
    domains: 100,
    institution: 128,
    notes: 1000,
    teamName: 320,
};

const licenseInfo = {
    licenseCount: 10,
    licenseType: 10,
};

const accessInfo = {
    geocountry: 20,
    ip: 15,
    ll: 10,
};

const inviteInfo = {
    inviteCode: 20,
};

export const dbLengthDefinitions = {
    ...deviceInfo,
    ...browserInfo,
    ...teamInfo,
    ...licenseInfo,
    ...accessInfo,
    ...inviteInfo,
    failedAttempts: 10,
    firstName: 100,
    indexMax: 24,
    lastName: 100,
    min: 2,
    one: 1,
    otpMaxLength: 30,
    password: 97,
    passwordHash: 128,
    status: 10,
    timezone: 100,
    token: 96,
    two: 2,
    uid: 36,
    url: 300,
    urlMin: 5,
    username: 320,
    zero: 0,
};
